<template>
  <div>{{ getProductCount() }} Prodotti</div>
</template>

<script>
import get from "lodash/get";

export default {
  name: "OrdersTableInfo",

  methods: {
    getProductCount() {
      return get(this.data, `order.tot_prods`);
    },
  },
};
</script>
